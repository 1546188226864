import React, {FormEvent, MouseEvent} from 'react';
import {EditContactCard} from '../EditContactCard/EditContactCard';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import QRCode from 'qrcode.react';
import ContactCard from '../ContactCard/ContactCard';
import vCardFactory from '../lib/vCards-js';
import {Transition} from '../utils/Transition';

const defaultState: ContactState = {
  firstName: 'First',
  lastName: 'Last',
  cellPhone: '9191234567',
  email: 'first.last@youremail.com',
  url: 'https://contactcarrd.com',
  photoUrl: '',
  linkedIn: '',
  twitter: '',
  github: '',
  note: 'Write something about yourself to stand out!',
  organization: 'Organization',
  title: 'Title',
  vCardString: '',
  backgroundColor: '#e0e0e0',
  isEditModalOpen: false,
  isQrModalOpen: false,
  shared: false,
  isAboutModalOpen: true,
};

interface ContainerProps {
}

export interface ContactState {
  firstName: string;
  lastName: string;
  cellPhone: string;
  email: string;
  url: string;
  photoUrl: string;
  linkedIn: string;
  twitter: string;
  github: string;
  note: string;
  organization: string;
  title: string;
  vCardString: string;
  backgroundColor: string;
  isEditModalOpen: boolean;
  isQrModalOpen: boolean;
  shared: boolean;
  isAboutModalOpen: boolean;
}

export class ContactContainer extends React.Component<ContainerProps, ContactState> {
  vCard = vCardFactory();

  constructor(props: ContainerProps) {
    super(props);
    this.handleContactChange = this.handleContactChange.bind(this);
    this.handleEditContactModalOpen = this.handleEditContactModalOpen.bind(this);
    this.handleEditContactModalClose = this.handleEditContactModalClose.bind(this);
    this.handleQrModalOpen = this.handleQrModalOpen.bind(this);
    this.handleQrModalClose = this.handleQrModalClose.bind(this);
    this.handleAboutModalClose = this.handleAboutModalClose.bind(this);

    this.state = defaultState;

    if (new URLSearchParams(window.location.search).get("code")) {
      this.state = {
        ...this.state,
        ...JSON.parse(window.atob(new URLSearchParams(window.location.search).get("code") || '')),
        isAboutModalOpen: false,
      }
    }

    this.vCard.firstName = this.state.firstName;
    this.vCard.lastName = this.state.lastName;
    this.vCard.email = this.state.email;
    this.vCard.cellPhone = this.state.cellPhone;
    this.vCard.photo.attachFromUrl(this.state.photoUrl, 'JPEG');
    this.vCard.socialUrls = {
      facebook: '',
      flickr: '',
      linkedIn: this.state.linkedIn,
      twitter: this.state.twitter,
      github: this.state.github,
    };
    this.vCard.title = this.state.title;
    this.vCard.organization = this.state.organization;
    this.vCard.note = this.state.note;
    this.vCard.version = '3.0';
    this.state = {
      ...this.state,
      vCardString: this.vCard.getFormattedString(),
    }
    this.updateBackgroundColor(this.state.backgroundColor);
  }

  render() {
    return <>
      <Box display='flex' alignItems='center' justifyContent='center' height='100%' width='100%'>
        <ContactCard contactInfo={this.state} openEditContactModal={this.handleEditContactModalOpen}
                     openQrModal={this.handleQrModalOpen}/>
      </Box>
      <Dialog
        open={this.state.isEditModalOpen}
        onClose={this.handleEditContactModalClose}
        fullWidth={true}>
        <form onSubmit={this.handleEditContactModalClose} style={{width: '100%'}}>
          <DialogTitle>Edit ContactCarrd</DialogTitle>
          <DialogContent>
            <EditContactCard handleContactChange={this.handleContactChange} contactInfo={this.state}/>
          </DialogContent>
          <DialogActions>
            <Button type='submit' onClick={this.handleEditContactModalClose}>SAVE</Button>
          </DialogActions>
        </form>
      </Dialog>

      <Dialog
        open={this.state.isQrModalOpen}
        onClose={this.handleQrModalClose}
        TransitionComponent={Transition}
      >
        <DialogContent>
          <QRCode value={this.state?.vCardString || ''}
                  size={window.innerWidth < 450 ? window.innerWidth * 0.66 : window.innerWidth * 0.33}/>
        </DialogContent>
        <DialogActions>
          <Button type='button' onClick={this.handleQrModalClose}>CLOSE</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={this.state.isAboutModalOpen}
              onClose={this.handleAboutModalClose}
              TransitionComponent={Transition}>
        <DialogTitle>About ContactCarrd</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Welcome! I started ContactCarrd to solve a problem of hard to share contacts and make it easy for people to
            create virtual business card like things to share with others.
            <br/><br/>
            To start, close this modal and hit the "EDIT"
            button and put in any contact information you'd like to share with others and clear out any fields that you
            don't care about. When you're done, hit the "SHARE" button which will copy a URL to share in emails, text
            messages, whatever! There's also a QR code button to pop up a QR code that can be scanned by any smartphone
            camera app to pre-fill a contact in your contacts list.
            <br/><br/>
            Once shared, you can also add this app to your phone's homepage via the built-in share function in the
            browser to quickly open your ContactCarrd anytime.
            <br/><br/>
            If you have any questions or feature requests, feel free to email me at <a
            href='mailto:contactcarrd@gmail.com'>contactcarrd@gmail.com</a> or find me on Twitter <a
            href='https://twitter.com/contactcarrd'>@contactcarrd</a>.
          </DialogContentText>
          <DialogActions>
            <Button type='button' onClick={this.handleAboutModalClose}>Got it!</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>;
  }

  handleContactChange(newState: ContactState): void {
    this.setState(newState);
    this.vCard.firstName = newState.firstName ?? this.state.firstName;
    this.vCard.lastName = newState.lastName ?? this.state.lastName;
    this.vCard.email = newState.email ?? this.state.email;
    this.vCard.cellPhone = newState.cellPhone ?? this.state.cellPhone;
    this.vCard.photo.attachFromUrl(newState.photoUrl ?? this.state.photoUrl, 'JPEG');
    this.vCard.title = newState.title ?? this.state.title;
    this.vCard.organization = newState.organization ?? this.state.organization;
    this.vCard.note = newState.note ?? this.state.note;
    // TODO: There's an issue with socials on iOS, would need to copy in vcards-js and work with it directly or find another library
    // this.vCard.socialUrls.linkedIn = newState.linkedIn ?? this.state.linkedIn;
    // this.vCard.socialUrls.twitter = newState.twitter ?? this.state.twitter;
    // this.vCard.socialUrls.github = newState.github ?? this.state.github;
    this.setState({
      vCardString: this.vCard.getFormattedString(),
    });
    console.log(this.vCard.getFormattedString());
    this.updateBackgroundColor(newState.backgroundColor);
  }

  handleEditContactModalOpen(): void {
    this.setState({isEditModalOpen: true});
  }

  handleEditContactModalClose(event: FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement>): void {
    event.preventDefault();
    this.setState({isEditModalOpen: false});
  }

  handleQrModalOpen(): void {
    this.setState({
      vCardString: this.vCard.getFormattedString(),
    });
    this.setState({isQrModalOpen: true});
  }

  handleQrModalClose(): void {
    this.setState({isQrModalOpen: false});
  }

  handleAboutModalClose(): void {
    this.setState({isAboutModalOpen: false});
  }

  updateBackgroundColor(color: string): void {
    const bodyElement: HTMLElement | null = document.querySelector('body');
    if (bodyElement) {
      bodyElement.style.backgroundColor = color;
      document.querySelector(`meta[name='theme-color']`)!.setAttribute('content', color);
    }
  }
}