import React from 'react';
import {ContactState} from '../ContactContainer/ContactContainer';
import {Avatar, Box, Button, Card, CardActions, CardContent, CardMedia, Link, Typography} from '@mui/material';
import {withParamsAndNavigate} from '../utils/withParamsAndNavigate';
import {NavigateFunction} from 'react-router-dom';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import {ContactPage, GitHub, LinkedIn, Twitter} from '@mui/icons-material';

const defaultState: ContactCardState = {
  shareButtonText: 'SHARE',
};

interface ContactCardState {
  shareButtonText: string;
}

interface ContactCardProps {
  contactInfo: ContactState;
  navigate: NavigateFunction;
  openEditContactModal: Function;
  openQrModal: Function;
}

class ContactCard extends React.Component<ContactCardProps, ContactCardState> {
  constructor(props: ContactCardProps) {
    super(props);
    this.generateSharableUrl = this.generateSharableUrl.bind(this);
    this.handleEditContactModalOpen = this.handleEditContactModalOpen.bind(this);
    this.handleQrModalOpen = this.handleQrModalOpen.bind(this);
    this.handleVCardDownload = this.handleVCardDownload.bind(this);

    this.state = defaultState;
  }

  render() {
    return <Box display='flex' alignItems='center' flexDirection='column'>
      <Card raised={true} style={{margin: '18px'}}>
        {this.props.contactInfo.photoUrl && this.props.contactInfo.photoUrl.length > 0 ? <CardMedia
            component='img'
            height={window.innerHeight / 2}
            image={this.props.contactInfo?.photoUrl}
            alt={`${this.props.contactInfo?.firstName} ${this.props.contactInfo?.lastName}`}/> :
          <Avatar sx={{height: window.innerHeight / 3, width: '100%'}} variant='rounded'>
            {this.props.contactInfo.firstName.slice(0, 1)}{this.props.contactInfo.lastName.slice(0, 1)}
          </Avatar>}
        <CardContent>
          <Typography gutterBottom variant='h5' component='div'>
            {this.props.contactInfo?.firstName} {this.props.contactInfo?.lastName}
          </Typography>
          <Typography gutterBottom variant='h6' component='div'>
            {this.props.contactInfo?.title}
            {this.props.contactInfo?.title && this.props.contactInfo?.organization ? ' at ' : ''}
            {this.props.contactInfo?.organization}
          </Typography>
          <Typography gutterBottom variant='body1' component='div'>
            {this.props.contactInfo?.note}
          </Typography>
          <Typography variant='body1' color='text.secondary'>
            {this.props.contactInfo?.email}
          </Typography>
          <Typography variant='body1' color='text.secondary'>
            {this.props.contactInfo?.cellPhone.length === 10 ? `${this.props.contactInfo?.cellPhone.slice(0, 3)}-${this.props.contactInfo?.cellPhone.slice(3, 6)}-${this.props.contactInfo?.cellPhone.slice(6)}` : this.props.contactInfo?.cellPhone}
          </Typography>
          <Link href={this.props.contactInfo?.url} target='_blank'>{this.props.contactInfo.url}</Link>
          <Box justifyContent='space-evenly' display='flex' paddingTop={this.hasSocials() ? 1 : 0} lineHeight={0}>
            {this.hasGithub() &&
            <Link href={this.props.contactInfo.github} target='_blank'><GitHub/></Link>}
            {this.hasLinkedIn() &&
            <Link href={this.props.contactInfo.linkedIn} target='_blank'><LinkedIn/></Link>}
            {this.hasTwitter() &&
            <Link href={this.props.contactInfo.twitter} target='_blank'><Twitter/></Link>}
          </Box>
        </CardContent>
        <CardActions sx={{justifyContent: 'space-between'}}>
          {!this.props.contactInfo.shared && <>
            <Button onClick={this.handleEditContactModalOpen}>EDIT</Button>
            <Button onClick={this.generateSharableUrl}>{this.state.shareButtonText}</Button>
          </>}
          <Button onClick={this.handleVCardDownload}
                  style={{marginRight: this.props.contactInfo.shared ? 'auto' : 'inherit'}}><ContactPage/></Button>
          <Button onClick={this.handleQrModalOpen}
                  style={{marginLeft: this.props.contactInfo.shared ? 'auto' : 'inherit'}}><QrCode2Icon/></Button>
        </CardActions>
      </Card>
      {this.props.contactInfo.shared && <Typography variant='body2'>
        Create your own ContactCarrd at <a href='https://contactcarrd.com'>https://contactcarrd.com</a>
      </Typography>}
    </Box>;
  }

  async generateSharableUrl(): Promise<void> {
    this.props.navigate(`?${new URLSearchParams({
      code: window.btoa(JSON.stringify({
        ...this.props.contactInfo,
        shared: true
      }))
    })}`, {replace: false});
    await navigator.clipboard.writeText(window.location.href);
    this.setState({shareButtonText: `COPIED TO CLIPBOARD`});
    setTimeout(() => {
      this.setState({shareButtonText: 'SHARE'});
    }, 3000);
  }

  handleEditContactModalOpen(): void {
    this.props.openEditContactModal();
  }

  async handleQrModalOpen(): Promise<void> {
    await this.generateSharableUrl();
    this.props.openQrModal();
  }

  async handleVCardDownload(): Promise<void> {
    const a: HTMLAnchorElement = document.createElement('a');
    const file: Blob = new Blob([this.props.contactInfo.vCardString], {type: 'text/vcard;charset=utf-8'});
    a.href = URL.createObjectURL(file);
    a.download = `${this.props.contactInfo.firstName}${this.props.contactInfo.lastName}.vcf`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  private hasSocials(): boolean {
    return this.hasGithub() || this.hasLinkedIn() || this.hasTwitter();
  }

  private hasGithub(): boolean {
    return this.props.contactInfo?.github !== '';
  }

  private hasLinkedIn(): boolean {
    return this.props.contactInfo?.linkedIn !== '';
  }

  private hasTwitter(): boolean {
    return this.props.contactInfo?.twitter !== '';
  }
}

export default withParamsAndNavigate(ContactCard);