import React, {ChangeEvent} from 'react';
import {ContactState} from '../ContactContainer/ContactContainer';
import {Box, Stack, TextField, Typography} from '@mui/material';
import {ColorResult, CompactPicker} from 'react-color';

interface EditContactCardProps {
  handleContactChange: Function;
  contactInfo: ContactState;
}

export class EditContactCard extends React.Component<EditContactCardProps> {
  constructor(props: EditContactCardProps) {
    super(props);
    this.onInputChange = this.onInputChange.bind(this);
    this.onBackgroundColorChange = this.onBackgroundColorChange.bind(this);
  }

  render() {
    return <Stack spacing={2} width='100%'>
      <TextField id='filled-basic' label='First Name' variant='filled' type='text' fullWidth
                 onChange={event => this.onInputChange(event, 'firstName')}
                 value={this.props.contactInfo.firstName}/>
      <TextField id='filled-basic' label='Last Name' variant='filled' type='text' fullWidth
                 onChange={event => this.onInputChange(event, 'lastName')}
                 value={this.props.contactInfo.lastName}/>
      <TextField id='filled-basic' label='Title' variant='filled' type='text' fullWidth
                 onChange={event => this.onInputChange(event, 'title')}
                 value={this.props.contactInfo.title}/>
      <TextField id='filled-basic' label='Organization' variant='filled' type='text' fullWidth
                 onChange={event => this.onInputChange(event, 'organization')}
                 value={this.props.contactInfo.organization}/>
      <TextField id='filled-basic' label='Note about yourself' variant='filled' type='text' multiline maxRows={5}
                 fullWidth
                 onChange={event => this.onInputChange(event, 'note')}
                 value={this.props.contactInfo.note}/>
      <TextField id='filled-basic' label='Email' variant='filled' type='email' fullWidth
                 onChange={event => this.onInputChange(event, 'email')}
                 value={this.props.contactInfo.email}/>
      <TextField id='filled-basic' label='Cell #' variant='filled' type='tel' fullWidth
                 onChange={event => this.onInputChange(event, 'cellPhone')}
                 value={this.props.contactInfo.cellPhone}/>
      <TextField id='filled-basic' label='Homepage/Portfolio URL' variant='filled' type='url' fullWidth
                 onChange={event => this.onInputChange(event, 'url')}
                 value={this.props.contactInfo.url}/>
      <TextField id='filled-basic' label='Photo URL' variant='filled' type='url' multiline maxRows={5} fullWidth
                 onChange={event => this.onInputChange(event, 'photoUrl')}
                 value={this.props.contactInfo.photoUrl}/>
      <TextField id='filled-basic' label='Github' variant='filled' type='url' fullWidth
                 onChange={event => this.onInputChange(event, 'github')}
                 value={this.props.contactInfo.github}/>
      <TextField id='filled-basic' label='LinkedIn' variant='filled' type='url' fullWidth
                 onChange={event => this.onInputChange(event, 'linkedIn')}
                 value={this.props.contactInfo.linkedIn}/>
      <TextField id='filled-basic' label='Twitter' variant='filled' type='url' fullWidth
                 onChange={event => this.onInputChange(event, 'twitter')}
                 value={this.props.contactInfo.twitter}/>
      <Typography gutterBottom variant='body1'>
        Background Color:
      </Typography>
      <Box marginLeft='auto !important' marginRight='auto !important'>
        <CompactPicker
          onChangeComplete={(color: ColorResult, event: ChangeEvent<HTMLInputElement>) => this.onBackgroundColorChange(color.hex)}
          color={this.props.contactInfo.backgroundColor}
        />
      </Box>
    </Stack>;
  }

  onBackgroundColorChange(hexColor: string): void {
    this.props.handleContactChange({
      backgroundColor: hexColor,
    });
  }

  onInputChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: keyof ContactState): void {
    this.props.handleContactChange({
      [field]: event.target.value,
    });
  }
}