import React from 'react';
import './App.css';
import {BrowserRouter} from 'react-router-dom';
import {ContactContainer} from './ContactContainer/ContactContainer';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

function App() {
  // @ts-ignore
  return (
    <div className='App'>
      <BrowserRouter>
        <ContactContainer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
